import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { motion, animate, useMotionValue } from 'framer-motion'
import { mobile } from '~styles/global'
import TileProduct from '~components/TileProduct'
import Num from '~components/Num'
import Button from '~components/Button'
import { LargeRarr, LargeLarr } from '~components/Svg'
import useBreakpoint from '~utils/useBreakpoint'
import useWindow from '~utils/useWindow'

const Catalogue = ({ className, data }) => {
	const [currentTab, setCurrentTab] = useState(0)
	const [isDragging, setIsDragging] = useState(false)
	const [sliderWidth, setSliderWidth] = useState(0)
	const [markers, setMarkers] = useState()
	const [slides, setSlides] = useState()
	const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0)
	const [sliderConstraints, setSliderConstraints] = useState(0)
	const [listenForTab, setListenForTab] = useState(true)
	const ref = useRef()
	const x = useMotionValue(0)
	const [liveX, setLiveX] = useState(0)
	const { isMobile } = useBreakpoint()
	const window = useWindow()

	const updateTab = i => {
		setCurrentTab(i)
		setListenForTab(false)
		const newPos = markers[i] < sliderConstraints ? -markers[i] : -sliderConstraints
		const controls = animate(x, newPos, {
			type: 'spring',
			stiffness: 33,
			onComplete: v => {
				setListenForTab(true)
			},
			onStop: v => {
				setListenForTab(true)
			}
		})
		return controls.stop
	}

	const goToNext = () => {
		let posX = 0 - x.current
		const nextSlides = slides.filter(slide => slide > posX)
		const newPos = nextSlides[0] < sliderConstraints ? -nextSlides[0] : -sliderConstraints
		const controls = animate(x, newPos, {
			type: 'spring',
			stiffness: 33,
			// onComplete: v => {}
		})
		return controls.stop
	}

	const goToPrev = () => {
		let posX = 0 - x.current
		const prevSlides = slides.filter(slide => slide < posX)
		const newPos = prevSlides[prevSlides.length - 1] > 0 ? -prevSlides[prevSlides.length - 1] : 0
		const controls = animate(x, newPos, {
			type: 'spring',
			stiffness: 33,
			// onComplete: v => {}
		})
		return controls.stop
	}

	const checkTab = latest => {
		if(!listenForTab) return
		let draggedTab = markers.findIndex(marker => marker >= ((0 - latest.x) + sliderWidth / 2))
		draggedTab = draggedTab === -1 ? markers.length : draggedTab
		draggedTab = draggedTab === 0 ? 0 : draggedTab - 1
		setCurrentTab(draggedTab)
	}

	useEffect(() => {
		if (!ref && !ref.current) return
		x.stop()
		x.set(0)
		const calcSliderChildrenWidth = () => {
			setSliderChildrenWidth(
				ref?.current?.scrollWidth
			)
		}

		calcSliderChildrenWidth()

		const calcSliderWidth = () => {
			setSliderWidth(ref?.current?.clientWidth)
		}

		calcSliderWidth()

		const calcSliderConstraints = () => {
			setSliderConstraints(sliderChildrenWidth - sliderWidth)
		}

		calcSliderConstraints()

		const calcMarkers = () => {
			const children = ref?.current.children
			if(children){
				const markers = [...children].filter(child => child.nodeName === 'DIV')
				setMarkers(markers.map(marker => marker.offsetLeft))
			}
		}

		calcMarkers()

		const calcSlides = () => {
			const children = ref?.current.children
			if(children){
				const slides = [...children].filter(child => child.nodeName === 'A')
				setSlides(slides.map(marker => marker.offsetLeft))
			}
		}

		calcSlides()
		
	}, [ref, window?.innerWidth])

	useEffect(() => x.onChange(latest => setLiveX(latest)), [])

	function handleTabPan(event, info) {
		let dir = info.delta.x > 0 ? 'prev' : 'next'
		if(dir === 'prev'){
			updateTab(currentTab > 0 ? currentTab - 1 : 0)
		}
		if(dir === 'next'){
			updateTab(currentTab >= (data?.items?.length - 1) ? currentTab : currentTab + 1)
		}
	}

	return (
		<Wrap className={className}>
			<TabsWrap>
				<Tabs
					onPanStart={isMobile ? handleTabPan : null}
					style={{
						x: isMobile ? window?.innerWidth / 2 - (110 + currentTab * 220) : 0
					}}
				>
					{data.items?.map((tab, i) => (
						<TabButton key={tab.category?.title} onClick={() => updateTab(i)}css={css`
						color: ${i === currentTab ? 'var(--black)' : '#C6C3B8'};
					`}>
							<h1>{tab.category?.title} <Num>{tab.products?.length}</Num></h1>
							<TabDesc>{tab.category?.description}</TabDesc>
						</TabButton>
					))}
				</Tabs>
			</TabsWrap>
			<VariantsWrap>
				<Variants
					drag="x"
					ref={ref}
					initial={{ x: 0 }}
					style={{ x }}
					onDragStart={() => setIsDragging(true)}
					onDragEnd={() => setIsDragging(false)}
					onUpdate={latest => checkTab(latest)}
					dragConstraints={{ left: 0 - sliderConstraints, right: 0}}
				>
					{data.items.map((item, i) => (
						<>
							<Marker />
							{item.products.map(product => (
								<Slide key={product.content?.shopify?.variantId} product={product} css={css`
									pointer-events: ${isDragging ? 'none' : 'all'};
								`}/>
							))}
						</>
					))}
				</Variants>
			</VariantsWrap>
			<Footer>
				<Prev onClick={() => goToPrev()} css={css`
					opacity: ${liveX > -5 ? 0.2 : 1};
					pointer-events: ${liveX > -5 ? 'none' : 'all'};
				`}><LargeLarr/></Prev>
				<RangeButton to='/store'><h3>Full Range</h3></RangeButton>
				<Next onClick={() => goToNext()} css={css`
					opacity: ${liveX < -sliderConstraints + 5 ? 0.2 : 1};
					pointer-events: ${liveX < -sliderConstraints + 5 ? 'none' : 'all'};
				`}><LargeRarr/></Next>
			</Footer>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`

const TabsWrap = styled.div`
	overflow: hidden;
	border-bottom: 1px solid var(--black);
`

const Tabs = styled(motion.div)`
	display: flex;
	transition: transform 0.5s;
	position: relative;
	&:before{
		content: '';
		display: none;
		position: absolute;
		top: 0;
		left: -400px;
		right: 0px;
		bottom: 0;
		${mobile}{
			display: block;
		}
	}
	&:after{
		content: '';
		display: none;
		position: absolute;
		top: 0;
		right: -400px;
		left: 0px;
		bottom: 0;
		${mobile}{
			display: block;
		}
	}
	${mobile}{
		display: block;
		white-space: nowrap;
	}
	`

const TabButton = styled.button`
	width: 100%;
	padding: var(--xxl) var(--l) var(--xl);
	transition: color 0.4s;
	${mobile}{
	 width: 220px;
	 pointer-events: none;
	 > {
		 pointer-events: none;
	 }
	}
	`

const TabDesc = styled.p`
	margin-top: var(--s);
	`

const VariantsWrap = styled.div`
	overflow: hidden;
	cursor: grabbing;
`

const Variants = styled(motion.div)`
	white-space: nowrap;
	> * {
		white-space: normal;
	}
	`

const Slide = styled(TileProduct)`
	display: inline-block;
	box-sizing: border-box;
	width: calc(33.3333vw + 1px);
	vertical-align: top;
	position: relative;
	&:after{
		content: "";
		background: var(--black);
		position: absolute;
		top: 0;
		right: 0;
		bottom: -999px;
		width: 1px;
	}
	&:last-child::after{
		display: none;
	}
	${mobile}{
		width: calc(100vw + 1px);
	}
	img{
		pointer-events: none;
	}
	`

const Marker = styled.div`
	display: inline-block;
	`

const Footer = styled.footer`
	height: var(--xxxl);
	border-bottom: 1px solid var(--black);
	border-top: 1px solid var(--black);
	display: grid;
	grid-template-columns: 32px 1fr 32px;
	align-items: center;
	padding: 0 var(--m);
	${mobile}{
		grid-template-columns: 21px 1fr 21px;
	}
	`
const RangeButton = styled(Button)`
	display: inline-flex;
	margin: 0 auto;
`
const Prev = styled.button`
	transition: opacity 0.3s;
	width: 30px;
	svg{
		transition: transform 0.3s;
		display: block;
	}
	&:hover{
		svg{
			transform: translateX(-3px);
		}
	}
	${mobile}{
		width: 20px;
	}
`

const Next = styled(Prev)`
	&:hover{
		svg{
			transform: translateX(3px);
		}
	}
`

Catalogue.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
}

export default Catalogue